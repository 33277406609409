<template>
  <div>
    <p class="fz28rem mb2rem ">医技沟通常见不恰当描述</p>
    <p class="fz22rem mb1rem mt6rem ">避免使用过于生僻的技术性的词汇</p>
    <div class="fz16rem help_center_line_height">
      <p>例如：欲行PAOO手术，重新定位上下颌前牙位置。</p>
      <p>建议：无需告知公司方案细节，具体以牙齿移动为导向提出修改意见。</p>
      <p>示范：上下颌前牙追加去釉，增加1mm内收 。</p>
    </div>
    <p class="fz22rem mb1rem mt6rem ">避免使用笼统词汇</p>
    <div class="fz16rem help_center_line_height">
      <p>例如：请减少邻面去釉 。</p>
      <p>建议：需提出减少去釉的量/部位等。</p>
      <p>示范：去除14-15、24-25之间去釉。</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>例如：请增加前牙内收。</p>
      <p>建议：需告知获取间隙方式，及目标位内收量。</p>
      <p>示范：追加去釉，增加前牙内收1mm。</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>例如：继续排齐前牙。</p>
      <p>建议：需告知具体不齐牙位及调整方向，可能存在审美不同情况。</p>
      <p>示范：32-33存在台阶，32远中舌侧扭转3°。</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>例如：改善覆𬌗覆盖。</p>
      <p>建议：需告知牙齿如何调整，如何处理间隙及覆𬌗覆盖调整目标。</p>
      <p>示范：继续压低下颌前牙1.5mm过矫治，间隙不足可考虑追加去釉，达到前牙0mm覆𬌗1mm覆盖。</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>例如：适当上下颌磨牙远移，减少去釉。</p>
      <p>建议：需考虑智齿、远移量及辅助手段问题后，提出减少去釉的量/部位等 。</p>
      <p>示范：拔除18、28、38、48，上下颌各远移2mm，减少前牙去釉，配合4个象限4颗种植钉加强支抗。</p>
    </div>
    <p class="fz22rem mt6rem mb1rem ">避免意见不明确 or 矛盾</p>
    <div class="fz16rem help_center_line_height">
      <p>例如：请设计拔牙方案 ，未告知拔牙牙位。</p>
      <p>建议：设计师无法代替临床医生制定患者治疗计划，需告知具体拔牙牙位，支抗类型，辅助手段等信息。</p>
      <p>示范：拔出18、28、38、48、14、24、34、44，使用格登特A7技术设计，上颌强支抗内收前牙，下颌中度支抗设计，配合II类牵引辅助。</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>例如：请设计磨牙远移方案，患者拒绝拔除智齿。</p>
      <p>建议：磨牙远移方案，通常需要拔除智齿，临床医生在提出修改意见时，需再次分析患者资料，当治疗方案和患者要求或牙颌结构存在矛盾时，应直接告知格登特解决方案，而不应提交存在矛盾的修改意见，否则设计师将无法进行修改。</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>例如：降低难度，未告知无法处理。</p>
      <p>建议：设计师无法得知临床医生可配合手段降低难度，需告知设计师。</p>
      <p>示范：35扭转度较大，配合交互牵引纠正，降低难度。</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>例如：对隐形矫治能力不知情，提出不合理要求。</p>
      <p>建议：格登特发出方案步数是计算机考虑牙齿移动类型及支抗需要最优方案，在相同牙齿移动终末位置下，要求缩短矫治器XX步，设计师在不清楚临床方案情况下，无法操作，建议该情况主动可预约沟通。</p>
    </div>
    <p class="fz22rem mt6rem mb1rem ">避免疑问式回复</p>
    <div class="fz16rem help_center_line_height">
      <p>(1) 是否可以设计一套拔除14、24、34、44方案？</p>
      <p>(2) 该方案是否可行？</p>
      <p>(3) 是否可以降低矫治难度？缩短矫治周期？</p>
      <p>(4) 是否可以不配合种植钉？</p>
    </div>
    <div class="fz16rem mt6rem help_center_line_height">
      <p>注：如表达有困难可预约沟通，您可以通过以下方式：</p>
      <p>(1) 拨打028-81706893联系格登特科技专属客服，进行电话预约 ；</p>
      <p>(2) 联系当地业务员，告知沟通需求，预约沟通 ；</p>
      <p>(3) InnoBuilder页面联系客服功能，在线客服预约。</p>
    </div>
    <p class="fz22rem mt6rem mb1rem ">语言尽量言简意赅 简洁明了</p>
    <div class="fz16rem help_center_line_height">
      <p>修改意见避免长篇大论，思路可参考以下方面：</p>
      <p>(1) 修改意见可聚焦为设计本身，告知牙齿调整意见，无需告知格登特公司临床思考过程；</p>
      <p>(2) 了解格登特设计规则，如常规拔牙病例A7设计方案，对前牙转矩、尖牙牙轴、磨牙牙轴、前牙垂直向会主动设计过矫治，无需再次告知设计师；</p>
      <p>(3) 按照 “矢状向-垂直向-横向-辅助手段”思路整理方案意见；</p>
      <p>(4) 语言表达尽量精简明了，以清晰的条目条理性列出。</p>
    </div>
    <p class="fz16rem mt6rem mb2rem">举例：</p>
    <div class="fz16rem help_center_line_height">
      <p>病例背景：上下颌前牙拥挤，前牙II°深覆𬌗，方案减数：14、24、34、44。</p>
      <p>医生修改意见：本方案中右侧上颌尖牙需往腭侧、远中，右上磨牙需往后一些，才能达到一个良好的中性关系。 拜托请修改：前牙需要整理，前牙控根（即加根舌侧转矩，使前牙相对唇倾）。后牙控根（即根稍近中倾斜备抗）。主要是压低下颌前牙，使上下颌处于一个平面上，而不是一个曲面上。调整磨牙、尖牙关系至中性。下颌后牙前移、上颌尖牙后移为主，使后牙咬合达到中性且咬合紧密，同时使尖牙呈中性关系。内收前牙、关闭间隙。（支抗是相互的，后牙肯定也会前移，不用额外施加后牙前移的设计）精细调整。 此外，应该上颌要用种植钉，请预留精密切割位置。 非常感谢！</p>
    </div>
    <p class="fz22rem mt6rem mb1rem ">建议提交方式：</p>
    <div class="fz16rem help_center_line_height">
      <p>(1) 矢状向：拔牙间隙设计中度支抗，以11近中切角为参考：上前牙内收5mm，下颌前牙参考上颌位置，剩余间隙后牙近移关闭；</p>
      <p>(2) 垂直向：压低前牙打开咬合，以下前牙为主，整平Spee曲线；</p>
      <p>(3) 横向：维持宽度，中线维持；</p>
      <p>(4) 辅助手段：13、23预留牵引钩，配合双侧后牙种植钉加强支抗。</p>
    </div>
    <p class="fz22rem mt6rem mb1rem ">避免非医学设计类意见反馈</p>
    <div class="fz16rem help_center_line_height">
      <p>(1) 请赠送一套车针；</p>
      <p>(2) 请加快，患者着急。</p>
      <p>注：非医学设计类问题可及时与当地业务员保持联系。</p>
    </div>
    <p class="fz22rem mt6rem mb1rem ">避免误当实时通讯软件使用</p>
    <div class="fz16rem help_center_line_height">
      <p>(1) 你好 在吗 ？有人吗？</p>
      <p>(2) 请设计师看到后回电。</p>
      <p class="mt6rem">注：非医学设计类问题可及时与当地业务员保持联系。</p>
<!--      <p>(1) 拨打40060-51717联系格登特专属客服，进行电话预约；</p>-->
      <p>(1) 联系当地业务员，告知沟通需求，预约沟通；</p>
      <p>(2) OrthoBuilder页面联系客服功能，在线客服预约。</p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {

    },

    methods: {

    },

    beforeDestroy() {
    },
    created() {
    }
  }
</script>

<style scoped>

</style>